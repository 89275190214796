import React, { useEffect, useState } from 'react'
import { apiInit } from 'services/http_common'


const defaultData = {
  peso_calc: null,
  fecha_calc: null,
  token: null,
  urlOrigin: null,
  info: null
}

export const AppContext = React.createContext(defaultData);

const AppContextProvider = ({ children }) => {
  const [data, setData] = useState(defaultData);

  const setInfo = (info) => {
    setData({ ...data, info })
  }

  const setPesoCalc = (peso_calc) => {
    setData({ ...data, peso_calc })
  }

  const setToken = (token) => {
    setData({ ...data, token })
  }

  const getToken = () => data.token

  useEffect(() => {
    const { token } = data;
    if(token) apiInit( token );
  }, [data.token])

  return (
    <AppContext.Provider 
      // @ts-ignore
      value={{ 
        data,
        info: data.info, 
        peso_calc: data.peso_calc, 
        token: data.token,
        setData, 
        setInfo, 
        setPesoCalc,
        setToken
      }
    }>
      { children }
    </AppContext.Provider>
  );
};

export default AppContextProvider;
