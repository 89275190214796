import 'core-js/es/set'
import 'core-js/es/map'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

// css from landing
import './assets/css/Landing/bootstrap.css'
import './assets/css/Landing/main.min.css'
// import './assets/css/Landing/animate.css'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-T5ZVQ3D',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
