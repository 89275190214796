import React, { Suspense } from 'react'
import routes from 'routes/route'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import AppContextProvider from 'context/AppProvider'

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Suspense fallback={<div>Cargando...</div>}>
          <Switch>
            {routes.map((route, index)=>(
              <Route 
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props)=> <route.Component {...props} />}
              />
            ))}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
