import axios from 'axios'
const url = process.env.REACT_APP_API_URL;
const api = axios.create({
  //appengine
  baseURL:
    url,
  headers: {
    'Content-type': 'application/json',
  }
})

export const apiInit = (token) => {
  api.interceptors.request.use(request => {
    if (token) request.headers.Authorization = token;
    return request;
  })
  api.interceptors.response.use(response => response);
}

export default api
