
import { lazy } from 'react';
const Transaction = lazy(() => import('../components/Order/Transaction/Transaction'));
const Insured = lazy(() => import('../views/Insured'));
const PreviewOrder = lazy(() => import('../views/PreviewOrder'));
const PaymentBtn = lazy(() => import('../components/Order/PaymentBtn'));
const SummaryOrder = lazy(() => import('../views/SummaryOrder'));
const WebpayError = lazy(() => import('../views/WebpayError'));
const QR = lazy(() => import('../views/QR'));
const Almost = lazy(() => import('../views/Almost'));
const Done = lazy(() => import('../views/Done'));
const Home = lazy(() => import('../views/Home'));
const NotFound = lazy(() => import('../views/NotFound'));

const routes = [
  {
    exact: true,
    path: "/payment",
    Component: Transaction,
  },
  {
    exact: true,
    path: "/insured",
    Component: Insured,
  },
  {
    exact: true,
    path: "/previeworder",
    Component: PreviewOrder,
  },
  {
    exact: true,
    path: "/paymentbtn",
    Component: PaymentBtn,
  },
  {
    exact: true,
    path: "/summaryorder",
    Component: SummaryOrder,
  },
  {
    exact: true,
    path: "/webpayerror",
    Component: WebpayError,
  },
  {
    exact: true,
    path: "/qr",
    Component: QR,
  },
  {
    exact: true,
    path: "/almost",
    Component: Almost,
  },
  {
    exact: true,
    path: "/done",
    Component: Done,
  },
  {
    exact: true,
    path: "/",
    Component: Home,
  },
  {    
    Component: NotFound,
  }
]

export default routes;
